import React from "react"
import { navigate } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { use100vh } from "react-div-100vh"
import { FormConfig, SiteMetaConfig } from "../config/config-data"
import SizeFix from "../utils/sizefix"
import "../styles/global.css"


const FeedbackProblemPage = ({ location }) => {

  const [realPassword, setRealPassword] = React.useState('')

  const handlePasswordChange = event => {
    setRealPassword(event.target.value);
  };
  

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    data.delete('Password');
    const values = Object.fromEntries(data.entries());
    if (realPassword.trim().length !== 0) {
      {FormConfig.form_spam_email &&
        fetch(FormConfig.form_spam_email, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: JSON.stringify(values)
        })
      }
    } else {
      fetch(FormConfig.form_email, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify(values)
      })
    }
  };

  return (
  <>
    <GatsbySeo
      title={`Please Tell Us Your Problem | ${SiteMetaConfig.site_name}`}
      description={SiteMetaConfig.site_description}
      canonical={`${SiteMetaConfig.site_url}${location.pathname}`}
      openGraph={{
        type: "website",
        url: `${SiteMetaConfig.site_url}${location.pathname}`,
        title: `Please Tell Us Your Problem | ${SiteMetaConfig.site_name}`,
        description: SiteMetaConfig.site_description,
        images: [
          { url: SiteMetaConfig.site_image },
        ],
        site_name: SiteMetaConfig.site_name,
      }}
      twitter={{
        handle: `@${SiteMetaConfig.site_twitter_handle}`,
        site: `@${SiteMetaConfig.site_name}`,
        cardType: "summary_large_image",
      }}
      facebook={{
        appId: SiteMetaConfig.site_facebook_app_id,
      }}
      htmlAttributes={{
        lang: SiteMetaConfig.site_language,
      }}
      noindex={true}
      nofollow={true}
    />
    <SizeFix />
    <div id="feedbackproblem_1662796539362" style={{minHeight: use100vh(), display: "grid", alignItems: "center"}}>
      <div id="feedbackproblem_1662796540927" className="Card Card_Container">
        <div id="feedbackproblem_1662796542392" className="Sub_Heading_Text">We are genuinely ashamed for your inconvinience!</div>
        <form id="feedbackproblem_1662796543472" onSubmit={handleSubmit}>
          <label className="Real_Password" htmlFor="Password">Password:</label>
          <input
            id="Password"
            name="Password"
            type="password"
            className="Real_Password"
            onChange={handlePasswordChange}
            autoComplete="new-password"
          />
          <div id="feedbackproblem_1662796545189" style={{textAlign:"left"}}>
            <label id="feedbackproblem_1662796546319" className="Text_Card">
              <div id="feedbackproblem_1662796547646" className="Normal_Text">Please tell us your problem so we can resolve it as quick as possible:</div>
              <textarea id="feedbackproblem_1662796548838" name="Complain" className="Text_Input" enterKeyHint="done" required/>
            </label>
            <label id="feedbackproblem_1662796550135" className="Text_Card">
              <div id="feedbackproblem_1662796551334" className="Normal_Text">Email (optional):</div>
              <input id="email" type="email" name="email" className="Text_Input" enterKeyHint="done"/>
            </label>
            <input id="feedbackproblem_1662796555014" type="hidden" name="_subject" value="New Complain!" />
          </div>
          <button id="feedbackproblem_1662796557069" type="submit" onClick={() => {navigate("/thankyou/")}} enterKeyHint="done">SUBMIT</button>
        </form>
      </div>
    </div>
  </>
  );
}
export default FeedbackProblemPage